/* === Global === */

html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

svg {
  display: block;
}
